<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-radio
          class="col-12"
          inline
          :options="types"
          select="value"
          v-model="selected">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>
    </div>

    <DetailPrint
      class="col-12"
      :key="selected"
      :paperId="paperId"
      :paperType="paperType"
      :templateType="templateType"
      :formData="formData"
      :url="url"
      :filename="filename">
    </DetailPrint>
  </div>
</template>

<script>
import DetailPrint from '@/views/paper_template/components/DetailPrint'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      types: [
        {text: 'Solidwork', value: 'Solidwork'},
        {text: 'Fusion360', value: 'Fusion360'}
      ],
      selected: 'Fusion360'
    }
  },
  computed: {
    currentRevision () {
      return this.formData.topics[this.formData.topics.length -1]
    },
    canPrint () {
      return this.currentRevision.users.findIndex(v => this.$auth.getUserName() == v.distributedUser.name) !== -1
    },
    url () {
      if (this.selected === 'Solidwork') return `/paper/${this.paperType}/${this.paperId}/print/solidwork`
      else return `/paper/${this.paperType}/${this.paperId}/print/fusion360`
    },
    filename () {
      return `${this.formData.code}_Rev${this.currentRevision.revision}.pdf`
    }
  },
  components: {
    DetailPrint
  }
}
</script>

<style lang="css" scoped>
</style>
