<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">

    <label slot="label">
      <span
        v-for="type in groupsByFilter"
        :key="type.value"
        class="pointer"
        :class="{'text-muted': type.value !== selectedGroup}"
        @click="setGroup(type.value)">
        {{type.text}}&nbsp;
      </span>
    </label>

    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { DP_INVENTORIES } from './graph'

export default {
  name: 'DetailFormInventory',
  props: {
    select: {
      type: String,
      required: false
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    oneWay: {
      type: Boolean,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    types: {
      type: Array,
      required: false,
      default() {
        return [
          'product', 'material', 'store', 'factory', 'expense', 'mould',
          'machine', 'instrument', 'vehicle', 'stationary', 'software'
        ]
      }
    }
  },
  data () {
    return {
      display: null,
      options: [],
      search: '',
      groups: [
        {text: 'สินค้า', value: 'product'},
        {text: 'วัตถุดิบ', value: 'material'},
        {text: 'สโตร์', value: 'store'},
        {text: 'อุปกรณ์สำนักงาน', value: 'stationary'},
        {text: 'โรงงาน', value: 'factory'},
        {text: 'เครื่องจักร', value: 'machine'},
        {text: 'แม่พิมพ์', value: 'mould'},
        {text: 'เครื่องตรวจวัด', value: 'instrument'},
        {text: 'ยานพาหนะ', value: 'vehicle'},
        {text: 'ค่าใช้จ่าย', value: 'expense'},
        {text: 'software', value: 'software'},
      ],
      selectedGroup: this.types[0]
    }
  },
  computed: {
    groupsByFilter () {
      return this.groups.filter(v => this.types.includes(v.value))
    },
  },
  methods: {
    setGroup (value) {
      this.selectedGroup = value
    },
    getDisplay (option) {
      return `${option.code} (${option.name})`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.$emit('change', option)
      this.emitInput(this.optionByKey(option))
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: DP_INVENTORIES(this.templateType),
        variables: {
          paperType: this.paperType,
          q: {
            limit: 10,
            params: {
              search: this.search,
              type: this.selectedGroup
            }
          }
        }
      })
      .then(res => {
        this.options = res.data.inventories
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }, 300),
    fetchData (id) {
      this.$apollo.query({
        query: DP_INVENTORIES(this.templateType),
        variables: {
          paperType: this.paperType,
          q: {
            limit: 1,
            params: {
              id
            }
          }
        }
      })
      .then(res => {
        const inventories = res.data.inventories
        this.options = inventories
        this.setDisplay(inventories[0])
        this.setGroup(inventories[0].type)
      })
      .catch(() => {
        this.options = []
        this.emitInput(null)
      })
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (value) {
        if (!this.oneWay && value) {
          this.fetchData(value)
        } else if (!value) {
          this.display = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">
</style>
