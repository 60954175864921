<template>
  <div>
    <div class="card">
      <div class="card-header d-flex align-items-center" v-if="!toggle">
        <span class="text-uppercase">Rev. {{topic.revision}}</span>
        <span v-if="method === 'edit'">
          <fa
            icon="pencil-alt"
            class="text-warning ml-1 pointer"
            @click="toggle = !toggle">
          </fa>
        </span>

        <button
          v-if="canDistribute"
          type="button"
          class="btn btn-sm btn-success ml-auto"
          @click="distributeTopic(topic.id)">
          รับเอกสาร
        </button>

        <button
          v-if="canReturn"
          type="button"
          class="btn btn-sm btn-danger ml-auto"
          @click="returnTopic(topic.id)">
          คืนเอกสาร
        </button>
      </div>
      <div class="card-body pb-0" v-if="!toggle">
        <div class="card-text">
          <dl class="row">
            <dt class="col-4 col-sm-3 font-weight-normal">วันที่อนุมัติ:</dt>
            <dd class="col-8 col-sm-9">{{topic.effectiveDate | date}}</dd>

            <dt class="col-4 col-sm-3 text-info font-weight-normal">รายละเอียด:</dt>
            <dd class="col-8 col-sm-9" style="white-space: pre-line;">
              {{topic.remark || '-'}}
            </dd>

            <dt class="col-4 col-sm-3 text-success font-weight-normal">รับเอกสาร:</dt>
            <dd class="col-8 col-sm-9">
              <div
                v-for="user in distributedUsers"
                :key="user.id">
                {{user.distributedUser.name}} ({{user.distributedAt | date}})
              </div>
              <span v-if="distributedUsers.length === 0">-</span>
            </dd>

            <dt class="col-4 col-sm-3 text-danger font-weight-normal">คืนเอกสาร:</dt>
            <dd class="col-8 col-sm-9">
              <div
                v-for="user in returnedUsers"
                :key="user.id">
                {{user.returnedUser.name}} ({{user.returnedAt | date}})
              </div>
              <span v-if="returnedUsers.length === 0">-</span>
            </dd>
          </dl>
        </div>
      </div>
      <div class="card-header" v-if="toggle">
        <div class="card-title">
          Revision:
          <fa
            icon="pencil-alt"
            class="text-warning ml-1 pointer"
            @click="toggle = !toggle">
          </fa>
        </div>

        <div class="form-row">
          <sgv-input-number
            v-model="formData.revision"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.revision.required && $v.formData.$dirty}
            ]">
          </sgv-input-number>

          <sgv-input-date
            label="วันที่อนุมัติ"
            v-model="formData.effectiveDate"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.effectiveDate.required && $v.formData.$dirty}
            ]">
          </sgv-input-date>

          <sgv-input-textarea
            label="รายละเอียด"
            v-model="formData.remark"
            class="col-12"
            auto-grow
            :validations="[
              {text: 'required!', value: !$v.formData.remark.required && $v.formData.$dirty}
            ]">
          </sgv-input-textarea>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateTopic(topic.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyTopic(topic.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_TOPIC,
  DESTROY_TOPIC,
  DISTRIBUTE_TOPIC_USER,
  RETURN_TOPIC_USER,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        revision: 0,
        effectiveDate: '',
        remark: ''
      }
    }
  },
  computed: {
    distributedUsers () {
      return this.topic.users.filter(usr => usr.type === 'distributed')
    },
    returnedUsers () {
      return this.topic.users.filter(usr => usr.type === 'returned')
    },
    canDistribute () {
      const userName = this.$auth.getUserName()
      const dis = this.distributedUsers.filter(v => v.distributedUser.name == userName)
      const ret = this.returnedUsers.filter(v => v.returnedUser.name == userName)
      return dis.length === ret.length
    },
    canReturn () {
      const userName = this.$auth.getUserName()
      const dis = this.distributedUsers.filter(v => v.distributedUser.name == userName)
      const ret = this.returnedUsers.filter(v => v.returnedUser.name == userName)
      return dis.length > ret.length
    }
  },
  validations: {
    formData: {
      revision: {required},
      effectiveDate: {required},
      remark: {required}
    }
  },
  methods: {
    updateTopic (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    distributeTopic (id) {
      this.$apollo.mutate({
        mutation: DISTRIBUTE_TOPIC_USER(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("รับสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    returnTopic (id) {
      this.$apollo.mutate({
        mutation: RETURN_TOPIC_USER(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("คืนสำเร็จ")
        this.$emit('updated', null)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    topic: {
      handler (v) {
        this.formData.revision = v.revision
        this.formData.effectiveDate = v.effectiveDate
        this.formData.remark = v.remark
      },
      immediate: true
    }
  }
}
</script>

<style lang="css">

</style>
